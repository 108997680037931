<header>
    <nav class="navbar fixed-top navbar-expand-lg bg-transparent navbar-light">
        <div class="container-sm">
            <a class="navbar-brand" href="#">
              <img src="/assets/images/logo/logo.png" width="120px" >
            </a>
            <button class="navbar-toggler" type="button" 
            type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            (click)="isCollapsed = !isCollapsed"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent"  [ngbCollapse]="isCollapsed">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <a class="nav-link" href="#">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Vietnam</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Singapore</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Philipine</a>
                </li>
              </ul>
            </div>
          </div>
    </nav>
</header>
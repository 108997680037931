import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-public',
  templateUrl: './header-public.component.html',
  styleUrls: ['./header-public.component.scss']
})

export class HeaderPublicComponent implements OnInit {

  public isCollapsed = true;
  
  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('bg-white');
      element.classList.add('shadow-sm');
    } else {
      element.classList.remove('bg-white');
      element.classList.remove('shadow-sm');
    }
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Product } from 'app/auth/models/product';
import { ObjectLocalStorage } from 'app/utils/constants';
import { environment } from 'environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EcommerceService implements Resolve<any> {
  // Public
  public productList: Array<any>;
  public wishlist: Array<any>;
  public cartList: Array<any>;
  public selectedProduct;
  public relatedProducts;

  public onProductListChange: BehaviorSubject<any>;
  public onRelatedProductsChange: BehaviorSubject<any>;
  public onWishlistChange: BehaviorSubject<any>;
  public onCartListChange: BehaviorSubject<any>;
  public onSelectedProductChange: BehaviorSubject<any>;

  // Private
  private idHandel;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(`${environment.wooTestKey}:${environment.wooTestSecret}`)
  });

  private sortRef = key => (a, b) => {
    const fieldA = a[key];
    const fieldB = b[key];

    let comparison = 0;
    if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }
    return comparison;
  };

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onProductListChange = new BehaviorSubject({});
    this.onRelatedProductsChange = new BehaviorSubject({});
    this.onWishlistChange = new BehaviorSubject({});
    this.onCartListChange = new BehaviorSubject({});
    this.onSelectedProductChange = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.idHandel = route.params.id;

    // return new Promise<void>((resolve, reject) => {
    //   // Promise.all([this.getProducts(), this.getWishlist(), this.getCartList(), this.getSelectedProduct()]).then(() => {
    //   //   resolve();
    //   // }, reject);

    //   Promise.all([this.getProducts()]).then(() => {
    //     resolve();
    //   }, reject);
    // });
  }

  /**
   * Get Products
   */
  getProducts(params = null, headers = {}) {
    for (let key in headers) {
      this.headers.append(key, headers[key]);
    }
    return this._httpClient.get<Product[]>(`${environment.apiUrl}/wp-json/wc/v3/products`, {headers: this.headers, params: params})
    // return new Promise((resolve, reject) => {
    //   this._httpClient.get<Product[]>(`${environment.apiUrl}/wp-json/wc/v3/products`, {headers: this.headers, params: params}).subscribe((response: any) => {
    //     this.productList = response;
    //     console.log(this.productList);
    //     // this.sortProduct('featured'); // Default shorting
    //     resolve(this.productList);
    //   }, reject);
    // });
  }

  getProduct(id, headers = {}) {
    for (let key in headers) {
      this.headers.append(key, headers[key]);
    }
    return this._httpClient.get<Product>(`${environment.apiUrl}/wp-json/wc/v3/products/${id}`, {headers: this.headers})
  }

  getCategory(params = null, headers = {}): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<Product[]>(`${environment.apiUrl}/wp-json/wc/v3/products/categories`, {headers: this.headers, params: params}).subscribe((response: any) => {
        this.productList = response;
        console.log(this.productList);
        // this.sortProduct('featured'); // Default shorting
        resolve(this.productList);
      }, reject);
    });
  }

  createOrder(data, headers = {}) {
    for (let key in headers) {
      this.headers.append(key, headers[key]);
    }
    return this._httpClient.post<any>(`${environment.apiUrl}/wp-json/wc/v3/orders`, data, {headers: this.headers})
  }

  getDetailCoupond(id, headers = {}) {
    return this._httpClient.get<Product[]>(`${environment.apiUrl}/wp-json/wc/v3/coupons/${id}`, {headers: {...this.headers, ...headers}})
  }

  getVariationsProduct(id, headers = {}) {
    console.log(`${environment.apiUrl}/wp-json/wc/v3/products/${id}/variations`);
    return this._httpClient.get<Product[]>(`${environment.apiUrl}/wp-json/wc/v3/products/${id}/variations`, {headers: this.headers})
  }

  /**
   * Get Wishlist
   */
  getWishlist(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/ecommerce-userWishlist').subscribe((response: any) => {
        this.wishlist = response;
        this.onWishlistChange.next(this.wishlist);
        resolve(this.wishlist);
      }, reject);
    });
  }

  /**
   * Get CartList
   */
  getCartList(): Promise<any[]> {
    return new Promise((resolve, reject) => {

      const eCart = localStorage.getItem(ObjectLocalStorage.E_CART);
      const eCartData = eCart ? JSON.parse(eCart) : null;
      const eCartItems  = eCartData ? eCartData.cart_items : [];
      resolve(eCartItems);

      // this._httpClient.get('api/ecommerce-userCart').subscribe((response: any) => {
      //   this.cartList = response;

      //   this.onCartListChange.next(this.cartList);
      //   resolve(this.cartList);
      // }, reject);
    });
  }

  /**
   * Get Selected Product
   */
  getSelectedProduct(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/ecommerce-products?id=' + this.idHandel).subscribe((response: any) => {
        this.selectedProduct = response;
        this.onSelectedProductChange.next(this.selectedProduct);
        resolve(this.selectedProduct);
      }, reject);
    });
  }

  /**
   * Get Related Products
   */
  getRelatedProducts(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/ecommerce-relatedProducts').subscribe((response: any) => {
        this.relatedProducts = response;
        this.onRelatedProductsChange.next(this.relatedProducts);
        resolve(this.relatedProducts);
      }, reject);
    });
  }

  /**
   * Sort Product
   *
   * @param sortBy
   */
  sortProduct(sortBy) {
    let sortDesc = false;

    const sortByKey = (() => {
      if (sortBy === 'price-desc') {
        sortDesc = true;
        return 'price';
      }
      if (sortBy === 'price-asc') {
        return 'price';
      }
      sortDesc = true;
      return 'id';
    })();

    const sortedData = this.productList.sort(this.sortRef(sortByKey));
    if (sortDesc) sortedData.reverse();
    this.productList = sortedData;
    this.onProductListChange.next(this.productList);
  }

  /**
   * Add In Wishlist
   *
   * @param id
   */
  addToWishlist(id) {
    return new Promise<void>((resolve, reject) => {
      const lengthRef = this.wishlist.length + 1;
      const wishRef = { id: lengthRef, productId: id };

      this._httpClient.post('api/ecommerce-userWishlist/' + lengthRef, { ...wishRef }).subscribe(response => {
        this.getWishlist();
        resolve();
      }, reject);
    });
  }

  /**
   * Remove From Wishlist
   *
   * @param id
   */
  removeFromWishlist(id) {
    const indexRef = this.wishlist.findIndex(wishlistRef => wishlistRef.productId === id); // Get the index ref
    const indexId = this.wishlist[indexRef].id; // Get the product wishlist id from indexRef
    return new Promise<void>((resolve, reject) => {
      this._httpClient.delete('api/ecommerce-userWishlist/' + indexId).subscribe((response: any) => {
        this.getWishlist();
        resolve();
      }, reject);
    });
  }

  /**
   * Add In Cart
   *
   * @param id
   */
  addToCart(id) {
    return new Promise<void>((resolve, reject) => {
      const maxValueId = Math.max(...this.cartList.map(cart => cart.id), 0) + 1;
      const cartRef = { id: maxValueId, productId: id, qty: 1 };
      var cartId: any = '';

      // If cart is not Empty
      if (maxValueId !== 1) {
        cartId = maxValueId;
      }
      this._httpClient.post('api/ecommerce-userCart/' + cartId, { ...cartRef }).subscribe(response => {
        this.getCartList();
        resolve();
      }, reject);
    });
  }

  /**
   * Remove From Cart
   *
   * @param id
   */
  removeFromCart(id) {
    const indexRef = this.cartList.findIndex(cartListRef => cartListRef.productId === id); // Get the index ref
    const indexId = this.cartList[indexRef].id; // Get the product wishlist id from indexRef

    return new Promise<void>((resolve, reject) => {
      this._httpClient.delete('api/ecommerce-userCart/' + indexId).subscribe((response: any) => {
        this.getCartList();
        resolve();
      }, reject);
    });
  }
}

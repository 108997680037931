import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicLayoutComponent } from './public-layout.component';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { ContentModule } from '../components/content/content.module';
import { FooterModule } from '../components/footer/footer.module';
import { MenuModule } from '../components/menu/menu.module';
import { NavbarModule } from '../components/navbar/navbar.module';
import { HeaderPublicComponent } from './header-public/header-public.component';
import { FooterPublicComponent } from './footer-public/footer-public.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    PublicLayoutComponent,
    HeaderPublicComponent,
    FooterPublicComponent
  ],
  imports: [
    CommonModule,
    RouterModule, CoreCommonModule, CoreSidebarModule, NavbarModule, ContentModule, MenuModule, FooterModule,
    NgbCollapseModule
  ],
  exports: [PublicLayoutComponent]
})
export class PublicLayoutModule { }

import { Injectable } from '@angular/core';
import { ObjectLocalStorage } from 'app/utils/constants';
import {BehaviorSubject} from 'rxjs';   

@Injectable({providedIn: 'root'})
export class StorageService {
 cartValue = new BehaviorSubject(this.theCart);
  currentCompoent = new BehaviorSubject(this.theComponent);

 set theCart(value) {
   this.cartValue.next(value); // this will make sure to tell every subscriber about the change.
   localStorage.setItem(ObjectLocalStorage.E_CART, value);
   if(!value) {
    localStorage.removeItem(ObjectLocalStorage.E_CART);
   }
 }

 get theCart() {
   return localStorage.getItem(ObjectLocalStorage.E_CART);
 }

 set theComponent(value) {
  this.currentCompoent.next(value); // this will make sure to tell every subscriber about the change.
   localStorage.setItem(ObjectLocalStorage.CURRENT_COMPONENT, value);
   if(!value) {
    localStorage.removeItem(ObjectLocalStorage.CURRENT_COMPONENT);
   }
 }

 get theComponent() {
  return localStorage.getItem(ObjectLocalStorage.CURRENT_COMPONENT);
 }

}